import React from 'react'
import { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'

function Join() {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_ylkzr3e', 'template_o1tzmge', form.current, '-Kzxe7MWO_rQnVt4V')
          .then((result) => {
              console.log(result.text);
              alert('email sent successfully');
              document.getElementById('emailInput').value = "";
              
          }, (error) => {
              console.log(error.text);
          });

          
      };

  return (
    <div className='join' id='join-us'>
      <div className="join-left">
        <hr />
        <div>
            <span className='stroke-text'>READY TO</span>
            <span>LEVEL UP</span>
        </div>
        <div>
            <span>YOUR BODY</span>
            <span className='stroke-text'>WITH US?</span>
        </div>
      </div>
      <div className="join-right">
        <form ref={form} action="" className="email-container" onSubmit={sendEmail}>
            <input id='emailInput' type="email" name='userEmail' placeholder='Enter your email' />
            <button className="btn btn-join">Join Now</button>
        </form>
      </div>
    </div>
  )
}

export default Join
