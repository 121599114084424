import React from 'react'
import Header from './Header/Header'
import './Hero.css'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import { motion } from 'framer-motion'
import NumberCounter from 'number-counter'

function Hero() {
    const transition = {type: 'spring', duration: 3};
    const mobile = window.innerWidth <= 768 ? true: false;
  return (
    <div className='hero' id='home'>
        <div className="blur hero-blur"></div>
      <div className="hero-left">
        <Header />

        {/* the best ad */}
        <div className="the-best-ad">
            <motion.div 
                initial = {{left: mobile ? "140px" : '190px'}}
                whileInView = {{left: '8px'}}
                transition = {{...transition, type: 'tween'}}>
            </motion.div>
            <span>the best fitness club in town</span>
        </div>

        {/* hero text  */}
        <div className="hero-text">
            <div>
                <span className='stroke-text'>shape </span>
                <span>your</span>
            </div>
            <div>
                <span>ideal </span>
                <span>body</span>
            </div>
            <div>
                <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
            </div>
        </div>

        {/* figures */}
        <div className="figures">
            <div>
                <span>
                    <NumberCounter start={100} end={140} delay='4' preFix="+"></NumberCounter>
                </span>
                <span>expert coaches</span>
            </div>
            <div>
                <span>
                    <NumberCounter start={678} end={800} delay='4' preFix="+"></NumberCounter>
                </span>
                <span>members joined</span>
            </div>
            <div>
                <span>
                    <NumberCounter start={30} end={50} delay='4' preFix="+"></NumberCounter>
                </span>
                <span>fitness programs</span>
            </div>
        </div>

        {/* hero buttons */}
        <div className="hero-buttons">
            <button className="btn">Get Started</button>
            <button className="btn">Learn More</button>
        </div>
      </div>

      <div className="hero-right">
        <button className='btn'>Join Now</button>

        <motion.div className="heart-rate"
            initial = {{right: "-1rem"}}
            whileInView = {{right: "4rem"}}
            transition={transition}>
            <img src={Heart} alt="" />
            <span>Heart Rate</span>
            <span>116 bpm</span>
        </motion.div>

        {/* hero images  */}
        <img src={hero_image} alt="" className='hero-image' />
        <motion.img 
        initial = {{right: "11rem"}}    
        whileInView = {{right: "20rem"}}
        transition = {transition}
        src={hero_image_back} alt="" className='hero-image-back' />

        <motion.div className="calories"
            initial = {{right: "37rem"}}
            whileInView = {{right: "28rem"}}
            transition = {transition}>
            <img src={Calories} alt="" />
            <div>
                <span>Calories Burned</span>
                <span>220 kcal</span>   
            </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Hero
